@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-Regular.eot');
  src: url('/static/fonts/Lato/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-Regular.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-Regular.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Lato Italic */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-Italic.eot');
  src: url('/static/fonts/Lato/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-Italic.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-Italic.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Lato Medium 500 */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-Medium.eot');
  src: url('/static/fonts/Lato/LatoLatin-Medium.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-Medium.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-Medium.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Lato Medium 500 Italic */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-MediumItalic.eot');
  src: url('/static/fonts/Lato/LatoLatin-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-MediumItalic.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-MediumItalic.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Lato Bold 700 */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-Bold.eot');
  src: url('/static/fonts/Lato/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-Bold.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-Bold.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Lato Bold 700 Italic */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-BoldItalic.eot');
  src: url('/static/fonts/Lato/LatoLatin-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-BoldItalic.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-BoldItalic.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Lato Heavy 800 */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-Heavy.eot');
  src: url('/static/fonts/Lato/LatoLatin-Heavy.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-Heavy.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-Heavy.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* Lato Heavy 800 Italic */
@font-face {
  font-family: 'Lato';
  src: url('/static/fonts/Lato/LatoLatin-HeavyItalic.eot');
  src: url('/static/fonts/Lato/LatoLatin-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('/static/fonts/Lato/LatoLatin-HeavyItalic.woff2') format('woff2'),
    url('/static/fonts/Lato/LatoLatin-HeavyItalic.woff') format('woff'),
    url('/static/fonts/Lato/LatoLatin-HeavyItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

/* overpass-100 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 100;
  src: url('/static/fonts/Overpass/overpass-v10-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-100.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-100.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-100.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-200 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 200;
  src: url('/static/fonts/Overpass/overpass-v10-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-200.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-200.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-300 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 300;
  src: url('/static/fonts/Overpass/overpass-v10-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-300.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-500 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/Overpass/overpass-v10-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-500.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-regular - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/Overpass/overpass-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-regular.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-600 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/Overpass/overpass-v10-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-600.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-700 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/Overpass/overpass-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-700.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-800 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 800;
  src: url('/static/fonts/Overpass/overpass-v10-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-800.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-800.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-900 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 900;
  src: url('/static/fonts/Overpass/overpass-v10-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-900.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-900.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-100italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 100;
  src: url('/static/fonts/Overpass/overpass-v10-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-100italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-100italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-100italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-100italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-100italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-200italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 200;
  src: url('/static/fonts/Overpass/overpass-v10-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-200italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-200italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-200italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-200italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-200italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-300italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 300;
  src: url('/static/fonts/Overpass/overpass-v10-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-300italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 400;
  src: url('/static/fonts/Overpass/overpass-v10-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('/static/fonts/Overpass/overpass-v10-latin-italic.svg#Overpass')
      format('svg'); /* Legacy iOS */
}
/* overpass-500italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 500;
  src: url('/static/fonts/Overpass/overpass-v10-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-500italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-500italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-600italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 600;
  src: url('/static/fonts/Overpass/overpass-v10-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-600italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-600italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-600italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-600italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-600italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-700italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 700;
  src: url('/static/fonts/Overpass/overpass-v10-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-700italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-800italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 800;
  src: url('/static/fonts/Overpass/overpass-v10-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-800italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-800italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-800italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-800italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-800italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-900italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 900;
  src: url('/static/fonts/Overpass/overpass-v10-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('/static/fonts/Overpass/overpass-v10-latin-900italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/static/fonts/Overpass/overpass-v10-latin-900italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-900italic.woff')
      format('woff'),
    /* Modern Browsers */ url('/static/fonts/Overpass/overpass-v10-latin-900italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/static/fonts/Overpass/overpass-v10-latin-900italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
